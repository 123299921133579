import React from "react";
import {InputAlpha} from "../input/InputAlpha";
import {PhoneInputAlpha} from "../input/PhoneInputAlpha";
import {SelectAlpha} from "../input/SelectAlpha";
import {EmailInputAlpha} from "../input/EmailInputAlpha";
import {InputNumberAlpha} from "../input/InputNumberAlpha";
import {Error} from "./Error";
import {PasswordInputAlpha} from "../input/PasswordInputAlpha";
import {InputImageAlpha} from "../input/InputImageAlpha";
import {TextAreaAlpha} from "../input/TextAreaAlpha";
import {IconButton, InputAdornment} from "@material-ui/core";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {Country, State, City} from "country-state-city";
import {CountryStateSelectAlpha} from "../input/CountryStateSelectAlpha";
import {DateTimeAlpha} from "../input/DateTimeAlpha";
import {SlideAlpha} from "../input/SlideAlpha";
import {LabelInputAlpha} from "../input/LabelInputAlpha";
import {SlideInput} from "../input/SlideInput";

export function AlphaForm(props) {

    const mainColor = '#17597C'
    let form = props.data
    let formData = []
    let cities = []
    let data = props.formData
    form.forEach(f => {  
        let label = f.required ? f.label + ' *' : f.label
        let _placeHolder = f.placeHolder ? f.placeHolder : f.label
        let placeHolder = f.required && _placeHolder ? _placeHolder + ' *' : _placeHolder

        let labelClassName = props.labelClassName ? props.labelClassName : 'font-Roboto text-lg text-alphaTauText'
        let inputClassName = props.inputClassName
        let isError = false

        let error = ""
        if (props.isShown && props.isShown[f.name]) {
            isError = props.isError && !!props.isShown[f.name]
            error = !f.error || f.error === "" ? props.isShown[f.name] : f.error
        }


        if (f.type === 'text') {
            let value = String(data[f.name])
            if ((value && value === "null") || value === "undefined") {
                value = ''
            }
            formData.push(
                <div className={"mt-5 w-max"} key={props.index ? f.name + props.index : f.name}>
                    <InputAlpha
                        isError={isError}
                        containerClassName={props.containerClassName}
                        inputClassName={props.inputClassName}
                        onChange={props.onChange}
                        disabled={f.disabled}
                        placeholder={placeHolder}
                        labelClassName={labelClassName}
                        name={f.name}
                        label={label}
                        maxLength={"40"}
                        value={value ? String(value) : ''}
                    />
                    <Error
                        message={error}
                        isShown={isError}/>
                </div>)
        } else if (f.type === 'slide') {

            formData.push(
                <SlideInput
                    leftLabel={"Disable"}
                    marginTop ={'pt-[4rem]'}
                    name={f.name}
                    disabled={f.disabled}
                    key={props.index ? f.name + props.index : f.name}
                    onChange={props.onChange}
                    rightLabel={"Active"}
                    value={data[f.name]}
                    step={1}
                    min={0}
                    max={1}
                />
            )
        } else if (f.type === 'label') {
            formData.push(<div className={"mt-5"} key={props.index ? f.name + props.index : f.name}>
                <LabelInputAlpha
                    label={label}
                    labelClassName={labelClassName}
                    labelInputClassName={props.labelInputClass}
                    value={data[f.name]}
                /></div>)
        } else if (f.type === 'textArea') {
            formData.push(<div className={"mt-5"} key={props.index ? f.name + props.index : f.name}><TextAreaAlpha
                isError={isError}
                containerClassName={props.containerClassName}
                textAreaInputClassName={props.textAreaInputClassName}
                onChange={props.onChange}
                disabled={f.disabled}
                placeholder={placeHolder}
                labelClassName={labelClassName + props.labelClassTextArea}
                name={f.name}
                label={label}
                maxLength={"500"}
                textAreaStyle={props.textAreaStyle}
                value={data[f.name] && data[f.name] !== "" ? data[f.name] : ''}
            />
                <Error
                    message={error}
                    isShown={isError}/>
            </div>)
        } else if (f.type === 'phone') {
            formData.push(<div className={"mt-5 "} key={props.index ? f.name + props.index : f.name}><
                PhoneInputAlpha
                isError={isError}
                key={f.name}
                containerClassName={props.containerClassName}
                inputClassName={inputClassName}
                name={f.name}
                disabled={f.disabled}
                country={props.country ? props.country?.toLowerCase() : "us"}
                // onChange={props.onChange}
                onChange={val => props.onChange({target: {name: f.name, value: val}})}
                placeholder={placeHolder}
                labelClassName={labelClassName}
                label={label}
                value={data[f.name] ? data[f.name] : ''}
            />
                <Error
                    message={error}
                    isShown={isError}/>
            </div>)
        } else if (f.type === 'select') {
            let filteredArray = f.data ? f.data : [];
            let singleOptionPerEntry = f.singleOptionPerEntry
            let chosenOptions = props.chosenOptions ? props.chosenOptions : []
            if (singleOptionPerEntry) {
                filteredArray = filteredArray.map((element) => {
                    if (chosenOptions.some(x => x.value === element.value))
                        element['isDisabled'] = true
                    else
                        element['isDisabled'] = false
                    return element
                })
            }
            formData.push(<div className={"mt-5"} key={props.index ? f.name + props.index : f.name}><SelectAlpha
                isError={isError}
                containerClassName={props.containerClassName}
                inputClassName={inputClassName}
                name={f.name}
                placeholder={placeHolder}
                disabled={f.disabled}
                onChange={val => props.onChange({target: {name: f.name, value: val}})}
                data={filteredArray}
                labelClassName={labelClassName}
                label={label}
                isIcon={f.isIcon}
                svgName={f.iconName}
                value={data[f.name] ? data[f.name] : ''}
                selected={data[f.name] ? data[f.name] : ''}
            />
                <Error
                    message={error}
                    isShown={props.isError && props.isShown && props.isShown[f.name]}/>
            </div>)

        } else if (f.type === 'dateTime') {
            formData.push(<div className={"mt-5"} key={props.index ? f.name + props.index : f.name}><DateTimeAlpha
                isError={isError}
                containerClassName={props.containerClassName}
                inputClassName={inputClassName}
                name={f.name}
                placeholder={placeHolder}
                onChange={val => props.onChange({target: {name: f.name, value: val}})}
                data={f.data}
                labelClassName={labelClassName}
                label={label}
                isClearable={props.isClearable} 
                disabled={f.disabled}
                minDate={f.minDate ? f.minDate : props.minDate}
                maxDate={data.hasOwnProperty(f.name+"_MAX") ? data[f.name+"_MAX"]:null}
                isIcon={f.isIcon} 
                svgName={f.iconName}
                value={data[f.name] ? data[f.name] : ''}
                selected={data[f.name] ? data[f.name] : ''}
            />
                <Error
                    message={error} 
                    isShown={props.isError && props.isShown[f.name]}/>
            </div>)

        } else if (f.type === 'country') {
            formData.push(<div className={"mt-5"} key={props.index ? f.name + props.index : f.name}>
                <CountryStateSelectAlpha
                    isError={isError}
                    containerClassName={props.containerClassName}
                    inputClassName={inputClassName}
                    name={f.name}
                    placeholder={placeHolder}
                    disabled={f.disabled}
                    onChange={val => props.onChange({target: {name: f.name, value: val}})}
                    data={(Country.getAllCountries()).map(country => {
                        return {label: country.name, value: country.isoCode}
                    })}
                    labelClassName={labelClassName}
                    label={label}
                    value={data[f.name] ? data[f.name] : ''}
                    selected={data[f.name] ? data[f.name].value : ''}

                />
                <Error
                    message={error}
                    isShown={isError}/>
            </div>)
        } else if (f.type === 'state') {
            if (props.formData['State']?.value) {
                cities = City.getCitiesOfState(props.formData['Country']?.value, props.formData['State']?.value).map(city => {
                    return {label: city.name, value: city.name}
                })
            } else if ((props.formData['Country']?.value && !props.formData['State']?.value)) {
                cities = City.getCitiesOfCountry(props.formData['Country']?.value).map(city => {
                    return {label: city.name, value: city.name}
                })
            }

            let disabled = f.disabled || State.getStatesOfCountry(props.formData['Country']?.value).length === 0
            formData.push(<div className={"mt-5"} key={props.index ? f.name + props.index : f.name}>
                <CountryStateSelectAlpha
                    isError={isError}
                    containerClassName={props.containerClassName}
                    inputClassName={inputClassName}
                    name={f.name}
                    placeholder={placeHolder}
                    disabled={disabled}
                    onChange={val => props.onChange({target: {name: f.name, value: val}})}
                    data={State.getStatesOfCountry(props.formData['Country']?.value).map(state => {
                        return {label: state.name, value: state.isoCode}
                    })}
                    labelClassName={labelClassName}
                    label={label}
                    value={data[f.name] ? data[f.name] : ''}
                />
                <Error
                    message={error}
                    isShown={isError}/>
            </div>)
        } else if (f.type === 'city') {

            formData.push(<div className={"mt-5"} key={props.index ? f.name + props.index : f.name}>
                <CountryStateSelectAlpha
                    isError={isError}
                    containerClassName={props.containerClassName}
                    inputClassName={inputClassName}
                    name={f.name}
                    disabled={f.disabled}
                    placeholder={placeHolder}
                    // disabled={City.getCitiesOfState(props.formData['State']).length === 0}
                    onChange={val => props.onChange({target: {name: f.name, value: val}})}
                    data={cities}
                    // data={City.getCitiesOfCountry(props.formData['State']?.value).map(state => { return {label: state.name, value: state.isoCode} })}
                    labelClassName={labelClassName}
                    label={label}
                    value={data[f.name] ? data[f.name] : ''}
                />
                <Error
                    message={error}
                    isShown={isError}/>
            </div>)
        } else if (f.type === 'email') {
            formData.push(<div className={"mt-5"}
                               key={props.index ? f.name + props.index : f.name}><EmailInputAlpha
                isError={isError}
                key={props.index ? f.name + props.index : f.name}
                containerClassName={props.containerClassName}
                inputClassName={inputClassName}
                name={f.name}
                disabled={f.disabled}
                handleKeyPress={props.handleKeyPress}
                placeholder={label}
                onChange={props.onChange}
                data={f.data}
                maxLength={"40"}
                labelClassName={labelClassName}
                label={label}
                value={data[f.name] && data[f.name] !== "" && data[f.name] !== "null" && data[f.name] !== null ? String(data[f.name]) : ''}
            />
                <Error
                    message={error}
                    isShown={isError}/>
            </div>)
        } else if (f.type === 'image') {
            formData.push(<div className={"mx-0.5"} key={props.index ? f.name + props.index : f.name}><InputImageAlpha
                isError={isError}
                containerClassName={props.containerClassName}
                inputClassName={inputClassName}
                name={f.name}
                disabled={f.disabled}
                placeholder={placeHolder}
                onChange={props.onChange}
                data={f.data}
                labelClassName={'font-Roboto text-lg text-alphaTauText'}
                label={data[f.name]}
                fileName={props.fileName}
                previewImage={props.previewImage}
                value={data[f.name]}
                deletePhoto={props.deletePhoto}
                removeStyle={props.removeStyle}
            />
                <Error
                    message={error}
                    isShown={isError}/>
            </div>)
        } else if (f.type === 'number') {
            formData.push(<div className={"mt-2"} key={props.index ? f.name + props.index : f.name}>
                <InputNumberAlpha
                    isError={isError}
                    containerClassName={props.containerClassName}
                    inputClassName={inputClassName}
                    name={f.name}
                    placeholder={placeHolder}
                    disabled={f.disabled}
                    onChange={props.onChange}
                    data={f.data}
                    labelClassName={labelClassName}
                    label={label}
                    max={f.max}
                    min={f.min}
                    value={data[f.name] ? data[f.name] : ''}
                />
                <Error
                    message={error}
                    isShown={isError}/>
            </div>)
        } else if (f.type === 'password') {
            formData.push(
                <div className={"mt-5"} key={props.index ? f.name + props.index : f.name}>
                    <PasswordInputAlpha
                        isError={isError}
                        containerClassName={props.containerClassName}
                        inputClassName={inputClassName}
                        name={f.name}
                        placeholder={placeHolder}
                        handleKeyPress={props.handleKeyPress}
                        disabled={f.disabled}
                        onChange={props.onChange}
                        data={f.data}
                        labelClassName={labelClassName}
                        label={label}
                        maxLength={"40"}
                        type={props.type}
                        value={data[f.name] ? data[f.name] : ''}
                        showOnClick={props.showOnClick}
                        endAdornment={(
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => props.showOnClick()}
                                >
                                    {props.showPassword ? <Visibility style={{color: mainColor}}/> :
                                        <VisibilityOff style={{color: mainColor}}/>}
                                </IconButton>
                            </InputAdornment>
                        )}
                    />
                    {!props.disableErrorMessage && <Error
                        message={error}
                        isShown={isError}/>}

                </div>)
        } else if (f.type === 'password2') {
            formData.push(
                <div className={"mt-[1.875rem]"} key={props.index ? f.name + props.index : f.name}><PasswordInputAlpha
                    containerClassName={props.containerClassName}
                    inputClassName={inputClassName}
                    name={f.name}
                    placeholder={placeHolder}
                    disabled={f.disabled}
                    onChange={props.onChange}
                    data={f.data}
                    labelClassName={`hidden ${labelClassName}`}
                    label={""}
                    maxLength={"40"}
                    showPassword={props.type[f.name]}
                    type={props.type[f.name] ? 'text' : 'password'}
                    value={data[f.name] ? data[f.name] : ''}
                    showOnClick={async () => await props.showOnClick(f.name)}
                    endAdornment={(
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={async () => await props.showOnClick(f.name)}
                            >
                                {props.showPassword[f.name] ? <Visibility style={{color: mainColor}}/> :
                                    <VisibilityOff style={{color: mainColor}}/>}
                            </IconButton>
                        </InputAdornment>
                    )}
                />
                    <Error
                        message={error}
                        isShown={isError}/>
                </div>)
        }
    });


    return (
        <>
            {formData}
        </>

    )
}
