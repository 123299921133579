import React, {useEffect, useState} from "react";
import {InputLabel} from "@material-ui/core";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import CustomToolbar from './calendar/CustomToolbar'
import "react-big-calendar/lib/css/react-big-calendar.css";
import MakeBlockChamber from "../modals/BlockChamberModal";
import ChambersApi from "../../services/ApiServices/ChambersApi";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip';
import {AlertConfirm} from "../modals/Confirm";



const localizer = momentLocalizer(moment)

export function AlphaBigCalendar(props, onDeleteEvent ) {

    const [events, setEvents] = useState([]);
    const [selectedDates, setSelectedDates] = useState([]);
    const [openBlockModal, setOpenBlockModal] = useState(false);
      // const [block, setBlock] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [editSlot, setEditSlot] = useState(false);
    const [selectedBlockIndex, setSelectedBlockIndex] = useState(null);
    const today = new Date();
    const minDate =  today.setDate(today.getDate() - 1)


    useEffect( () => {
        setEvents(props.events)
        // setEvents(schedulerData)

    },[props.events])

    useEffect( () => {
        // setEvents(schedulerData)
        setOpenBlockModal(props.openBlock)
    },[props.openBlock])

    const handleSelectSlot = async ({ start, end }) => {
        if(!props.disabled){
            end.setDate(end.getDate() - 1);
            if (start < minDate) {
                return; // Don't allow navigating to previous months or days
            }else {
                setStartDate(start)
                setEndDate(end)
                setSelectedDates([...selectedDates, ...getDatesBetween(start, end)]);
                setOpenBlockModal(true)
            }
        }

    };

    const getDatesBetween = (start, end) => {
        const dates = [];
        const currentDate = moment(start);
        while (currentDate.isSameOrBefore(end)) {
            dates.push(currentDate.toDate());
            currentDate.add(1, 'days');
        }
        return dates;
    };

    const dayPropGetter = (date) => {

        if (isBeforeToday(date)) {
            return {
                className: 'before-today',
                style: {
                    backgroundColor: '#eee',
                    color: '#bbb',
                    opacity:'0.5'
                },
            };
        }
    }
    function isBeforeToday(date) {
        return moment(date).isBefore(moment(), 'day');
    }
    const handleSelectEvent = async (event) => {
        if(!props.disabled) {
            let selectedEvent = events.find(x => x === event)
            if (selectedEvent && selectedEvent.type === 'block') {
                setSelectedBlockIndex(event.Index)
                setStartDate(new Date(selectedEvent.start))
                setEndDate(new Date(selectedEvent.end))
                setEditSlot(true)
                await setOpenBlockModal(true)
            }
        }
    };
    const cancelBlock = async (type,event) => {
        if(!props.disabled) {
            if (type === 'cancel') {
                setOpenBlockModal(false)
                setEditSlot(false)
                props.closeBlockModal()
            } else {
                setOpenBlockModal(false)
                setEditSlot(false)
                let _events = [...events]
                let title = <div className={'w-full flex justify-center'}>Remove Block</div>
                let message = <div>Are you sure you want to remove this block?</div>
                let alert = await AlertConfirm({}, message, title)
                if (alert) {
                    let deleteBlock = ChambersApi.deleteBlockedChamber(selectedBlockIndex)
                    if (deleteBlock) {

                        let index = events.findIndex(x => x.Index === selectedBlockIndex)
                        _events.splice(index, 1)
                        setSelectedBlockIndex(null)
                        // onDeleteEvent(event);
                        setEvents(_events)
                    }
                }
            }
        }
    }

    const blockDate = (type,_block) => {

        if(!props.disabled) {
            let startDate = _block['startDate']
            let endDate = _block['endDate']
            startDate.setHours(_block['startHourType'] === 'PM' ? (Number(_block['startHour']) + 12) : Number(_block['startHour']))
            startDate.setMinutes(Number(_block['startMinute']))
            endDate.setHours(_block['endHourType'] === 'PM' ? (Number(_block['endHour']) + 12) : Number(_block['endHour']))
            endDate.setMinutes(Number(_block['endMinute']))
            _block['startDate'] = startDate
            _block['endDate'] = endDate

            if (type === 'create') {
                const newEvent = {
                    start: startDate.toISOString(),
                    end: endDate.toISOString(),
                    title: 'block',
                    type: 'block'
                };
                setEvents([...events, newEvent]);

            } else {
                let _events = [...events]
                let eventIndex = events.findIndex(e => e.Index === selectedBlockIndex)
                if (eventIndex > -1) {
                    _events[eventIndex] = _block
                    setEvents(_events)
                }
            }
            // setBlock(_block)
            props.setBlock(_block, type, selectedBlockIndex)
            setEditSlot(false)
            setOpenBlockModal(false)
        }
    }
    const eventColors = {
        order: '#17597C',
        block: 'black',
    };

    const eventStyleGetter = (event, start, end, isSelected) => {

        const backgroundColor = eventColors[event.type];
        const style = {
            backgroundColor,
            borderRadius: '5px',
            height:'1.418rem',
            opacity: 0.8,
            color: 'transparent',
            border: '0px',
            display: 'block',
        };
        return {
            style,
        };
    };

    const getTime=(isoString)=>{
        const date = new Date(isoString);
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        
        // Convert to 12-hour format
        hours = hours % 12;
        hours = hours ? hours : 12; // If hours is 0, display 12 instead
        const minutesStr = minutes < 10 ? '0' + minutes : minutes; // Pad minutes with leading zero if necessary
        
        return `${hours}:${minutesStr} ${ampm}`;
    }
    return (
        <div className={props.containerClassName}>
            <InputLabel className={props.labelClassName}>{props.label}</InputLabel>
            <Calendar
                localizer={localizer}
                views={["month"]}
                events={events}
                startAccessor="start"
                endAccessor="end"
                components={{
                    toolbar: CustomToolbar,
                    eventWrapper: ({ event, children }) => (
                        <OverlayTrigger
                            placement={'bottom'}
                            className="tooltip-blue"
                            overlay={<Tooltip id={`tooltip-${event.Index}`} className={'mt-4'}><div className={'toolTip-arrow'}></div><div style={{zIndex:'999'}} className={'bg-[#FFFFFF] p-4 border border-[#D0D0D0] rounded-[0.625rem]'}>
                                {event.ORDNAME &&
                                <div className={'text-[#444444] text-[1.125rem] mb-[0.25rem] mb-[1.438rem]'}><b>Order #:</b> {event.ORDNAME}</div>}
                            <div className={'text-[#444444] text-[1.125rem] mb-[0.25rem]'}><b>Start Time:</b> {`${getTime(event.startDate)}`} </div>
                             <div className={'text-[#444444] text-[1.125rem] mb-[0.25rem]'}><b>End Time:</b> {`${getTime(event.endDate)}`} </div>
                        </div></Tooltip>}>
                            {children}
                        </OverlayTrigger>
                    ),
                    // timeSlotWrapper: ({ children, value }) => {
                    //     console.log("********************",value)
                    //     console.log("selectedSlotselectedSlotselectedSlot",selectedSlot)
                    //     if (moment(value).isSame(selectedSlot)) {
                    //
                    //         return <div style={{ backgroundColor: 'red', color: 'white' }}>{children}</div>
                    //     }
                    //     return <div>{children}</div>
                    // }
                }}
                style={{ height: 550 ,width:'100%'}}
                min={minDate}
                onSelectSlot={handleSelectSlot}
                selectable
                selected={selectedDates}
                dayPropGetter={dayPropGetter}
                onSelectEvent={handleSelectEvent}
                eventPropGetter={eventStyleGetter}
                minTime={moment().hour(8).toDate()}
                maxTime={moment().hour(18).toDate()}
            />
            {
                openBlockModal &&
                    <MakeBlockChamber
                        isEdit={editSlot}
                        onCancel={cancelBlock}
                        startDate={startDate}
                        endDate={endDate}
                        blockDateTime={blockDate}
                    />
            }



        </div>

    )
}
