import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import SVGraphics from "../../assets/SVGraphics";
import Constants from "../../utils/constants";
import "react-datepicker/dist/react-datepicker.css";
import { TimeInputAlpha } from "../input/TimeInputAlpha";
import moment from "moment";


const BlockChamberModal = ({ onCancel, isEdit, startDate, endDate, blockDateTime }) => {

    const [block, setBlock] = useState({});
    const [disabled, setDisabled] = useState(false);
    // const [minutes, setMinutes] = useState([]);
    const types = ['Start', 'End']
    const getTime = (isoString) => {
        const date = new Date(isoString);
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Convert to 12-hour format
        hours = hours % 12;
        hours = hours ? hours : 12; // If hours is 0, display 12 instead
        const minutesStr = minutes < 10 ? '0' + minutes : minutes; // Pad minutes with leading zero if necessary

        return `${hours}:${minutesStr} ${ampm}`;
    }
    useEffect(() => {
        if (startDate && endDate) {

            const formatHour = (date) => {
                const hours = date.getHours();
                const formattedHours = hours > 12 ? hours - 12 : hours;
                return formattedHours < 10 ? '0' + formattedHours : '' + formattedHours;
            };

            const formatMinute = (date) => {
                const minutes = date.getMinutes();
                return minutes < 10 ? '0' + minutes : '' + minutes;
            };

            const formatHourType = (date) => {
                return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }).slice(-2);
            };

            const _block = {
                startDate: startDate,
                endDate: endDate,
                startHourType: isEdit ? formatHourType(startDate) : 'AM',
                endHourType: isEdit ? formatHourType(endDate) : 'PM',
                startMinute: isEdit ? formatMinute(startDate) : '00',
                startHour: isEdit ? formatHour(startDate) : '12',
                endHour: isEdit ? formatHour(endDate) : '11',
                endMinute: isEdit ? formatMinute(endDate) : '59'
            };

            setBlock(_block);
        }
    }, [startDate, endDate, isEdit]);

    const onChangeDate = (dates) => {
        let [_start, _end] = dates;
        let _block = { ...block }
        let __start = _start
        __start?.setHours(_block['startHour'])
        __start?.setMinutes(_block['startMinute'])
        let startH = __start ? __start?.getHours() > 12 ? __start?.getHours() - 12 : __start?.getHours() : null
        __start?.setHours(startH)
        _block['startDate'] = __start
        _block['endDate'] = _end
        setBlock(_block)
        if (__start >= _end) {
            if (moment(_block['endHour'] + _block['endMinute'], _block['endHourType']) >= moment(_block['startHour'] + _block['endMinute'], _block['endHourType'])) {
                setDisabled(true)
            } else {
                setDisabled(false)
            }
        }
    };
    const onChange = (type, value) => {
        let _block = { ...block }
        _block[type] = value.target.value
        setBlock(_block)
        if (_block['startDate'] >= _block['endDate']) {
            if (moment(_block['endHour'] + _block['endMinute'], _block['endHourType']) <= moment(_block['startHour'] + _block['endMinute'], _block['endHourType'])) {
                setDisabled(true)
            } else {
                setDisabled(false)
            }
        }
        // setStart(_start);
        // setEnd(_end);
    };


    return (
        <div id="modalEl" tabIndex="-1" aria-hidden="true"
            className="fixed top-0 left-0 right-0 w-auto z-50  py-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full bg-stone-900/60 flex items-center justify-center ">

            <div className="h-auto md:h-auto self-center p-5 w-auto">

                <div className="relative bg-white px-[5rem] w-auto rounded-lg shadow border border-indigo-600 dark:bg-gray-700">

                    {/*{isEdit &&*/}
                    <div
                        className={"rounded-full bg-[#F6F7F8] w-8 h-8 p-1 text-center float-right cursor-pointer top-[1rem] left-[15%] relative text-black font-semibold"}
                        onClick={() => onCancel('cancel')}>
                        &#10005;
                    </div>
                    {/*// }*/}
                    <div className="flex-col items-center w-full px-auto justify-center justify-items-center flex">
                        <div
                            className="flex-col items-center rounded-t w-full px-auto justify-center justify-items-center mt-5">
                            <div
                                className="text-[1.25rem] w-full font-semibold font-Roboto text-[#444444] mt-[0.938rem] text-left mb-[0.5rem]">
                                Block Date
                            </div>
                            <div className={"SelectAlphaIcon mb-6 h-[3.5rem] bg-[#F2F5FA] rounded-[0.625rem] w-[100%]"}>
                                <DatePicker
                                    selected={block['startDate']}
                                    onChange={onChangeDate}
                                    startDate={block['startDate']}
                                    endDate={block['endDate']}
                                    selectsRange
                                    minDate={new Date()}
                                    dateFormat="dd/MM/yyyy"
                                    // isClearable
                                    className={'ml-[3rem] h-[3.5rem] bg-[#F2F5FA] w-[100%]'}
                                />
                                <SVGraphics className={'select-input-icon'} svgname={'calendar'} />
                            </div>
                        </div>
                        <div
                            className="flex-col items-center rounded-t w-full px-auto justify-center justify-items-center">
                            <div
                                className="text-[1.25rem] font-semibold font-Roboto text-[#444444] mt-[0.938rem] text-left mb-[0.5rem]">
                                Block Time
                            </div>
                            {types.map(type => {
                                return <div className={'flex mt-[1rem]'}>
                                    <div className={'text-[1.125rem] text-[#444444] font-Roboto mr-[1.563rem] flex items-center w-[3rem]'}>{type}</div>
                                    <TimeInputAlpha
                                        containerClassName={'flex mt-[1rem]'}
                                        selectedHour={type === 'Start' ? block['startHour'] : block['endHour']}
                                        selsctedMinute={String(block[type === 'Start' ? 'startMinute' : 'endMinute'])}
                                        onChangeHours={(e) => onChange(type === 'Start' ? 'startHour' : 'endHour', e)}
                                        onChangeMinutes={(e) => onChange(type === 'Start' ? 'startMinute' : 'endMinute', e)}
                                        selectedHourType={block[type === 'Start' ? 'startHourType' : 'endHourType']}
                                        onChangeHourType={(e) => onChange(type === 'Start' ? 'startHourType' : 'endHourType', e)}
                                    />
                                </div>
                            })
                            }
                        </div>
                    </div>
                    <div className=" py-6 rounded-b justify-self-center mx-auto mt-5">
                        <button type="button"
                            onClick={() => blockDateTime((isEdit ? 'edit' : 'create'), block)}
                            disabled={disabled}
                            className={`${Constants.buttonClass} font-Roboto w-full hover:bg-alphaTauButtonHover text-white bg-alphaTauButton focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-[1.25rem] px-5 py-3 mb-4 text-center disabled:opacity-25`}>
                            Block
                        </button>
                        <button type="button"

                            onClick={() => onCancel((isEdit ? 'delete' : 'cancel'), block)}
                            className="text-alphaTauButton font-Roboto bg-transparent focus:ring-4 focus:outline-none border border-transparent text-[1.313rem] py-2.5 text-center w-full underline enabled:hover:text-blue-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600">
                            {isEdit ? 'Delete' : 'Cancel'}
                        </button>

                    </div>
                </div>

            </div>

        </div>
    )
        ;

}

export default BlockChamberModal;